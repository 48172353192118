const URL_PREFIX = 'data:image/svg+xml;charset=UTF-8;base64,';

let eventTypeIconsCache = {};
let eventTypeMarkerIconsCache = {};

const EventTypeIcons = {
    group: require('!svg-inline-loader!./event_types/events_group'),
    groupWithVideo: require('!svg-inline-loader!./event_types/events_group_with_video'),
    uncategorized: require('!svg-inline-loader!./event_types/uncategorized'),
    1: require('!svg-inline-loader!./event_types/ignition_on'),
    2: require('!svg-inline-loader!./event_types/ignition_off'),
    3: undefined, //require('!svg-inline-loader!./event_types/valid_fix'),
    4: undefined, //require('!svg-inline-loader!./event_types/invalid_fix'),
    5: require('!svg-inline-loader!./event_types/speeding_start'),
    6: require('!svg-inline-loader!./event_types/speeding_end'),
    7: require('!svg-inline-loader!./event_types/towing_start'),
    8: require('!svg-inline-loader!./event_types/idling_start'),
    9: require('!svg-inline-loader!./event_types/idling_end'),
    10: require('!svg-inline-loader!./event_types/acceleration'),
    12: require('!svg-inline-loader!./event_types/deceleration'),
    15: require('!svg-inline-loader!./event_types/pto_start'),
    16: require('!svg-inline-loader!./event_types/pto_end'),
    17: require('!svg-inline-loader!./event_types/towing_end'),
    18: require('!svg-inline-loader!./event_types/alarm_on'),
    21: require('!svg-inline-loader!./event_types/harsh_turn'),
    22: require('!svg-inline-loader!./event_types/crash_detection'),
    23: undefined, //require('!svg-inline-loader!./event_types/motion_on'),
    24: undefined, //require('!svg-inline-loader!./event_types/motion_off'),
    25: undefined, //require('!svg-inline-loader!./event_types/text_message'),
    26: undefined, //require('!svg-inline-loader!./event_types/canned_response'),
    27: undefined, //require('!svg-inline-loader!./event_types/live'),
    29: undefined, //require('!svg-inline-loader!./event_types/power_restored'),
    30: undefined, //require('!svg-inline-loader!./event_types/gps_lost'),
    31: undefined, //require('!svg-inline-loader!./event_types/went_to_sleep_mode'),
    32: undefined, //require('!svg-inline-loader!./event_types/antenna_connection_lost'),
    33: undefined, //require('!svg-inline-loader!./event_types/main_power_source_lost'),
    34: undefined, //require('!svg-inline-loader!./event_types/garmin_connection_lost'),
    35: undefined, //require('!svg-inline-loader!./event_types/temperature_out_of_range'),
    36: undefined, //require('!svg-inline-loader!./event_types/temperature_normalized'),
    37: undefined, //require('!svg-inline-loader!./event_types/gps_found'),
    38: undefined, //require('!svg-inline-loader!./event_types/in_coverage'),
    39: undefined, //require('!svg-inline-loader!./event_types/no_coverage'),
    40: undefined, //require('!svg-inline-loader!./event_types/sim_removed'),
    41: require('!svg-inline-loader!./event_types/fill_up_start'),
    42: require('!svg-inline-loader!./event_types/fill_up_end'),
    43: require('!svg-inline-loader!./event_types/drain_start'),
    44: require('!svg-inline-loader!./event_types/drain_end'),
    45: require('!svg-inline-loader!./event_types/jamming'),
    49: require('!svg-inline-loader!./event_types/seat_belt_unbuckled'),
    50: require('!svg-inline-loader!./event_types/seat_belt_buckled'),
    55: require('!svg-inline-loader!./event_types/low_external_voltage'),
    56: require('!svg-inline-loader!./event_types/external_voltage_normalized'),
    60: undefined, //require('!svg-inline-loader!./event_types/media_start'),
    61: undefined, //require('!svg-inline-loader!./event_types/media_end'),
    62: undefined, //require('!svg-inline-loader!./event_types/media'),
    63: require('!svg-inline-loader!./event_types/video_loss'),
    64: require('!svg-inline-loader!./event_types/lens_covered'),
    65: require('!svg-inline-loader!./event_types/storage_malfunction'),
    66: require('!svg-inline-loader!./event_types/low_voltage'),
    67: require('!svg-inline-loader!./event_types/driver_fatigue'),
    68: require('!svg-inline-loader!./event_types/no_driver'),
    69: require('!svg-inline-loader!./event_types/phone_calling'),
    70: require('!svg-inline-loader!./event_types/driver_smoking'),
    71: require('!svg-inline-loader!./event_types/driver_distracted'),
    72: require('!svg-inline-loader!./event_types/lane_departure_warning'),
    73: require('!svg-inline-loader!./event_types/forward_collision_warning'),
    74: require('!svg-inline-loader!./event_types/headway_monitoring_warning'),
    75: require('!svg-inline-loader!./event_types/yawn'),
    77: require('!svg-inline-loader!./event_types/intersection_speed_limit_alarm'),
    78: require('!svg-inline-loader!./event_types/crossing_intersection_without_stopping'),
    79: require('!svg-inline-loader!./event_types/power_failure_alarm'),
    80: require('!svg-inline-loader!./event_types/working_start'),
    81: require('!svg-inline-loader!./event_types/working_end'),
    82: require('!svg-inline-loader!./event_types/commuting_start'),
    83: require('!svg-inline-loader!./event_types/commuting_end'),
    84: require('!svg-inline-loader!./event_types/requested_video'),
    85: require('!svg-inline-loader!./event_types/tire_pressure_out_of_range'),
    86: require('!svg-inline-loader!./event_types/tire_pressure_normalized'),
    87: require('!svg-inline-loader!./event_types/forward_vehicle_start_alert'),
    88: require('!svg-inline-loader!./event_types/forward_proximity_warning'),
    92: require('!svg-inline-loader!./event_types/intersection_speed_limit_alarm'),
    93: require('!svg-inline-loader!./event_types/asset_detected'),
    94: require('!svg-inline-loader!./event_types/asset_lost'),
    100: require('!svg-inline-loader!./event_types/seat_belt_unbuckled'),
    101: require('!svg-inline-loader!./event_types/low_external_voltage'),
    102: require('!svg-inline-loader!./event_types/external_voltage_normalized'),
    109: require('!svg-inline-loader!./event_types/pedestrian_collision_warning'),
    110: require('!svg-inline-loader!./event_types/turbo_timer_violated'),
    111: require('!svg-inline-loader!./event_types/rpm_out_of_range'),
    112: require('!svg-inline-loader!./event_types/rpm_normalized'),
};

export default {
    getEventTypeMarkerURL(eventTypeId) {
        const cacheKey = eventTypeId;
        let url = eventTypeMarkerIconsCache[cacheKey];
        if (!url) {
            const iconSVG = this.getEventTypeMarkerSVG({
                contentSVG: EventTypeIcons[eventTypeId] || EventTypeIcons.uncategorized,
                color: this.getEventTypeMarkerColor(eventTypeId)
            });
            url = eventTypeMarkerIconsCache[cacheKey] = `${URL_PREFIX}${btoa(iconSVG)}`;
        }
        return url;
    },

    getEventTypeMarkerSVG(options) {
        const contentSVG = options.contentSVG || '';
        const color = options.color || Base.colors.UNKNOWN_EVENT_MARKER_COLOR;
        const borderColor = options.borderColor || '#FFFFFF';
        const backgroundColor = options.backgroundColor || '#FFFFFF';
        const contentColor = '#202326'

        return `
            <svg viewBox="0 0 21 32" width="25" height="34" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path d="M10.4975 0.402793C8.01035 0.392832 5.60654 1.2825 3.74495 2.90196C1.88336 4.52142 0.694378 6.75726 0.404956 9.18268C0.115535 11.6081 0.745949 14.0532 2.17586 16.0513C3.60577 18.0494 5.73503 19.4605 8.15719 20.0153L10.4975 31.6632L12.8378 20.0153C15.26 19.4606 17.3893 18.0494 18.8192 16.0514C20.2491 14.0533 20.8796 11.6081 20.5901 9.18268C20.3007 6.75725 19.1117 4.52141 17.2501 2.90194C15.3885 1.28247 12.9847 0.392817 10.4975 0.402793V0.402793Z"
                          stroke-width="1"
                          stroke="${borderColor}"
                          fill="${color}"/>
                    <path d="M10.5001 18.027C14.8026 18.027 18.2905 14.6025 18.2905 10.3781C18.2905 6.15381 14.8026 2.72931 10.5001 2.72931C6.19759 2.72931 2.70972 6.15381 2.70972 10.3781C2.70972 14.6025 6.19759 18.027 10.5001 18.027Z"
                          fill="${backgroundColor}"/>
                </g>
                <g>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" x="2" y="2" color="${contentColor}">
                        ${contentSVG}
                    </svg>
                </g>
            </svg>
        `;
    },

    getEventTypeMarkerColor(eventTypeId) {
        if (['group', 'groupWithVideo'].includes(eventTypeId)) {
            return Base.colors.EVENTS_GROUP_MARKER_COLOR;
        }

        return Base.events.getColor(eventTypeId);
    },

    getEventTypeIconURL(eventTypeId) {
        const cacheKey = eventTypeId;
        let url = eventTypeIconsCache[cacheKey];
        if (!url) {
            const svg = `
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 13 13" style="color: #202326;">
                    <g transform="scale(1.31, 1.31)">
                        ${EventTypeIcons[eventTypeId] || EventTypeIcons.uncategorized}
                    </g>
                </svg>
            `;
            url = eventTypeIconsCache[cacheKey] = `${URL_PREFIX}${btoa(svg)}`;
        }
        return url;
    },

    getMapEventIcon(eventTypeId) {
        return {
            iconUrl: this.getEventTypeMarkerURL(eventTypeId),
            iconSize: [25, 34],
            iconAnchor: [12, 34],
        };
    },

    getMapMultiEventIcon() {
        return {
            iconUrl: this.getEventTypeMarkerURL('group'),
            iconSize: [25, 34],
            iconAnchor: [12, 34],
        }
    },

    getMapMultiEventWithMediaIcon() {
        return {
            iconUrl: this.getEventTypeMarkerURL('groupWithVideo'),
            iconSize: [25, 34],
            iconAnchor: [12, 34],
        }
    },

};
